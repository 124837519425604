import { component } from 'picoapp'
import EmblaCarousel from 'embla-carousel'
import { on } from 'martha'

export default component((node, ctx) => {
  const embla = EmblaCarousel(node, {
    dragFree: true,
    align: 'start',
    containScroll: true,
  })

  on(node, 'click', ev => {
    if (!embla.clickAllowed()) {
      ev.preventDefault()
    }
  })

  return () => {
    embla.destroy()
  }
})
