import { component } from 'picoapp'
import Cookies from 'js-cookie'
import { on, toggle } from 'martha'

export default component((node, ctx) => {
  const mq = window.matchMedia('(prefers-reduced-motion: reduce)')
  const savedPreference = Cookies.get('prefers-reduced-motion') === 'true'
  const preference = savedPreference || mq.matches

  on(node, 'change', () => {
    check(node.checked)
  })

  check(preference)

  function check(state) {
    toggle(document.documentElement, 'prefers-reduced-motion', state)
    ctx.hydrate({ prefersReducedMotion: state })
    Cookies.set('prefers-reduced-motion', state, { expires: 365 })
    node.checked = state
  }
})
