import { each, qsa, qs, noop, on, add, remove, attr } from 'martha'
import { component } from 'picoapp'
import gsap from 'gsap'

export default component((node, ctx) => {
  let offInputFocus = noop
  let offInputBlur = noop
  let offSelectChange = noop

  function init() {
    const inputs = qsa('input,textarea', node)
    const selects = qsa('select', node)

    each(selects, el => {
      const label = getLabel(el)
      const option = document.createElement('option')

      option.disabled = true
      option.innerHTML = label.innerHTML
      option.setAttribute('selected', 'selected')
      option.setAttribute('value', '')

      each([...option.childNodes], n => {
        if (n.nodeType === 3) {
          n.textContent = n.textContent.trim()
        } else {
          n.remove()
        }
      })

      each([...el.children], o => {
        o.removeAttribute('selected')
      })

      el.prepend(option)
    })

    offInputFocus = on(inputs, 'focus', ({ currentTarget: el }) => {
      el._placeholder = attr(el, 'placeholder')
      attr(el, 'placeholder', '')
      const label = getLabel(el)
      gsap.set(label, { opacity: 1 })
    })

    offInputBlur = on(inputs, 'blur', ({ currentTarget: el }) => {
      attr(el, 'placeholder', el._placeholder)
      const label = getLabel(el)
      if (!el.value.length) {
        gsap.set(label, { opacity: 0 })
      }
    })

    offSelectChange = on(selects, 'change', ({ currentTarget: el }) => {
      const label = getLabel(el)
      if (el.value.length) {
        gsap.set(label, { opacity: 1 })
        gsap.set(el, { color: 'black' })
      } else {
        gsap.set([label, el], { clearProps: 'opacity,color' })
      }
    })
  }

  function getLabel(el) {
    return qs(`label[for=${attr(el, 'id')}]`, node)
  }

  setTimeout(init, 50)

  return () => {
    offInputFocus()
    offInputBlur()
    offSelectChange()
  }
})
