import choozy from 'choozy'
import { each, index, noop, on, qsa, rect, wrap } from 'martha'
import { component } from 'picoapp'
import gsap from 'gsap'

export default component((node, ctx) => {
  let { items, links, tab, dropdowns, dropdownBg, dropdownButtons } =
    choozy(node)

  // we always want these in an array, even if there is only one
  links = [].concat(links)
  dropdowns = [].concat(dropdowns)
  dropdownButtons = [].concat(dropdownButtons)

  const navContainsDropdowns = node.hasAttribute('data-has-dropdowns')

  const tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: () => (ctx.getState().prefersReducedMotion ? 0 : 0.3),
      ease: 'quint',
    },
  })

  let prevIndex = -1
  let currentIndex = -1

  ctx.on('resize', () => {
    node.rect = rect(node)

    each(items, el => {
      el.rect = rect(el)

      if (!el.tabbables) {
        el.tabbables = qsa('[data-tabbable]', el)
        el.focusedIndex = 0

        each(el.tabbables, (el, i) => {
          if (i > 0) el.setAttribute('tabindex', -1)
        })
      }
    })

    if (navContainsDropdowns) {
      each(dropdowns.concat(dropdownBg), el => {
        gsap.set(el, { clearProps: 'left' })
        el.rect = rect(el)
        gsap.set(el, { left: -node.rect.left })
      })
    }

    const dropdown = dropdowns.find(el => +el.dataset.index === currentIndex)

    if (dropdown) {
      gsap.set(dropdownBg, { height: rect(dropdown).height })
    }
  })

  let offClick = on(dropdownButtons, 'click', ev => {
    if (dropdownButtons.isOpen) {
      leave()
    } else {
      open(ev)
    }
  })

  let offClickOutside = on(document, 'click', ev => {
    if (currentIndex > -1 && !ev.target.closest('.js-items')) {
      close()
    }
  })

  let offKeyDown = on(window, 'keydown', ev => {
    if (currentIndex === -1) return

    if (ev.key === 'Escape') {
      close()
    }

    const dir = {
      ArrowUp: -1,
      ArrowDown: 1,
    }

    if (ev.key === 'ArrowUp' || ev.key === 'ArrowDown') {
      if (currentIndex > -1) {
        const item = items[currentIndex]
        if (item.tabbables.length) {
          ev.preventDefault()

          item.focusedIndex = wrap(
            item.focusedIndex + dir[ev.key],
            item.tabbables.length,
          )

          const target = item.tabbables[item.focusedIndex]

          if (target) {
            target.focus()
          }
        }
      }
    }
  })

  function open({ currentTarget }) {
    tl.clear()

    tl.set(dropdownButtons, { clearProps: 'opacity' }).set(currentTarget, {
      opacity: 1,
    })

    const item = currentTarget.closest('li')
    prevIndex = currentIndex
    currentIndex = index(item)

    if (prevIndex === currentIndex) {
      close()
      return
    }

    const dropdown = dropdowns.find(el => +el.dataset.index === currentIndex)
    const scaleX = item.rect.width / node.rect.width
    const x = item.rect.x - node.rect.x

    if (prevIndex === -1) {
      tl.set(tab, { scaleX, x })

      if (navContainsDropdowns && dropdown) {
        tl.set(dropdown, { autoAlpha: 1 })
        tl.set(dropdownBg, {
          autoAlpha: 1,
          height: dropdown.rect.height,
          scaleY: 0,
        })
      }
    } else {
      if (navContainsDropdowns) {
        if (dropdown) {
          tl.set(dropdownBg, {
            autoAlpha: 1,
            height: dropdown.rect.height,
            scaleY: rect(dropdownBg).height / dropdown.rect.height,
          })
            .set(dropdown, {
              pointerEvents: 'auto',
              autoAlpha: 0,
              '--b': 0,
            })
            .set(
              dropdown,
              {
                autoAlpha: 1,
              },
              0,
            )
        }

        const otherDropdowns = dropdowns.filter(el => el !== dropdown)
        if (otherDropdowns.length) {
          tl.set(
            otherDropdowns,
            {
              autoAlpha: 0,
              pointerEvents: 'none',
            },
            0,
          )
        }
      }
    }

    tl.to(
      tab,
      {
        autoAlpha: 1,
        scaleX,
        x,
      },
      0,
    )

    if (navContainsDropdowns && dropdown) {
      tl.to(
        dropdownBg,
        {
          scaleY: 1,
        },
        0,
      ).to(
        dropdown,
        {
          '--b': 0,
        },
        0,
      )
    } else {
      tl.to(
        dropdownBg,
        {
          scaleY: 0,
        },
        0,
      )
    }

    tl.restart()
  }

  function close() {
    currentIndex = -1

    tl.clear().set(dropdownButtons, { clearProps: 'opacity' }).set(
      tab,
      {
        autoAlpha: 0,
      },
      0,
    )

    if (navContainsDropdowns) {
      tl.to(
        dropdownBg,
        {
          scaleY: 0,
        },
        0,
      )
        .to(
          dropdowns,
          {
            '--b': 100,
          },
          0,
        )
        .set([dropdowns, dropdownBg], { autoAlpha: 0 })
    }

    tl.restart()
  }

  return () => {
    offClick()
    offClickOutside()
    offKeyDown()
  }
})
