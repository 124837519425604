import { component } from 'picoapp'
import choozy from 'choozy'
import instantsearch from 'instantsearch.js'
import {
  searchBox,
  refinementList,
  stats,
  hits,
  pagination,
} from 'instantsearch.js/es/widgets'
import { searchClient } from '../lib/algolia'
import { TYPES, TYPES_PLURAL, INDEX_NAME } from '../lib/constants'

export default component((node, ctx) => {
  const {
    searchBoxContainer,
    refinementListContainer,
    statsContainer,
    hitsContainer,
    paginationContainer,
  } = choozy(node)

  const search = instantsearch({
    searchClient,
    indexName: INDEX_NAME,
    routing: {
      stateMapping: {
        stateToRoute(uiState) {
          return { query: uiState[INDEX_NAME].query }
        },
        routeToState(routeState) {
          return {
            [INDEX_NAME]: routeState,
          }
        },
      },
    },
  })

  search.addWidgets([
    searchBox({
      container: searchBoxContainer,
      autofocus: true,
      templates: {
        submit(_, { html }) {
          return html`<svg class="w-16 s:w-24" viewBox="0 0 24 32" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.8404 19.8813C21.6291 17.2006 23.2699 11.2637 20.5051 6.62064C17.7404 1.97763 11.6171 0.386821 6.82838 3.06746C2.03969 5.74811 0.398958 11.6851 3.16371 16.3281C5.92847 20.9711 12.0517 22.5619 16.8404 19.8813ZM17.7506 21.4098C23.41 18.2418 25.349 11.2253 22.0816 5.73815C18.8142 0.250952 11.5776 -1.6291 5.91819 1.53893C0.258827 4.70697 -1.68022 11.7234 1.58722 17.2106C4.85466 22.6978 12.0913 24.5779 17.7506 21.4098Z"
              fill="#2E3034"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.6287 19.44L16.0522 20.3225L22.4235 31.0222L24 30.1397L17.6287 19.44Z"
              fill="#2E3034"
            />
          </svg>`
        },
        reset(_, { html }) {
          return html`<svg class="w-12 s:w-17" viewBox="0 0 19 19" fill="none">
            <path d="M1.13086 1.13135L17.8049 17.8054" stroke="#2E3034" />
            <path d="M1 17.6738L17.674 0.999781" stroke="#2E3034" />
          </svg>`
        },
      },
    }),
    refinementList({
      container: refinementListContainer,
      attribute: 'type',
      templates: {
        item(item, { html }) {
          const { url, label, isRefined } = item

          return html`
            <a class="flex items-center gap-x-8 s:gap-x-15" href="${url}">
              <span
                class="w-20 s:w-18 h-20 s:h-18 bg-black-10 border-1 border-black-30 grid place-items-center"
              >
                ${isRefined
                  ? html`<span class="block w-10 h-10 bg-black"></span>`
                  : null}
              </span>
              <span class="text-18 s:text-22 tracking-1 leading-100"
                >${TYPES_PLURAL[label]}</span
              >
            </a>
          `
        },
      },
    }),
    stats({
      container: statsContainer,
      templates: {
        text(data, { html }) {
          let count = ''

          if (data.hasManyResults) {
            count += `${data.nbHits} results`
          } else if (data.hasOneResult) {
            count += `1 result`
          } else {
            count += `no result`
          }

          return html`<span>${count} found</span>`
        },
      },
    }),
    hits({
      container: hitsContainer,
      templates: {
        item(hit, { html, components }) {
          return html`
            <a
              href="${hit.link}"
              class="block py-20 s:py-25 border-b-1 border-b-black-30 group"
            >
              <div class="space-y-10">
                <div
                  class="text-12 font-normal leading-100 tracking-6 uppercase text-gray-50"
                >
                  ${TYPES[hit.type]}
                </div>
                <div
                  class="text-20 s:text-26 leading-126 tracking-2 group-hover:underline"
                >
                  ${components.Highlight({ attribute: 'parent_title', hit })}
                </div>
                <div
                  class="text-16 s:text-20 leading-126 tracking-2 text-black-50 group-hover:underline"
                >
                  ${components.Snippet({ attribute: 'content', hit })}
                </div>
              </div>
            </a>
          `
        },
      },
    }),
    pagination({
      container: paginationContainer,
      showNext: false,
      showPrevious: false,
      showFirst: false,
      showLast: false,
    }),
  ])

  search.start()
})
