import { component } from 'picoapp'
import fitty from 'fitty'

export default component((node, ctx) => {
  const fitties = fitty(node, {
    maxSize: Infinity,
  })
  return () => {
    fitties.unsubscribe()
  }
})
