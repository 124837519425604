import choozy from 'choozy'
import { component } from 'picoapp'
import { each, on, toggle } from 'martha'

export default component((node, ctx) => {
  const { form, radios, tables } = choozy(node)

  if (radios?.length) {
    on(radios, 'change', () => {
      const newOptions = JSON.stringify(
        Object.values(Object.fromEntries(new FormData(form))),
      )

      each(tables, table => {
        const options = JSON.stringify(
          table.dataset.options.split(',').map(o => o.trim()),
        )

        toggle(table, 'hidden', options !== newOptions)
      })
    })
  }
})
