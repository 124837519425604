import choozy from 'choozy'
import { component } from 'picoapp'
import io from '../lib/io'

export default component((node, ctx) => {
  const { iframe } = choozy(node)

  const player = new Vimeo.Player(iframe)

  const offIo = io(node, {
    enter: () => player.play().catch(() => {}),
    exit: () => player.pause(),
  })

  return () => {
    offIo()
    player.destroy()
  }
})
