import choozy from 'choozy'
import { component } from 'picoapp'
import gsap from 'gsap'

export default component((node, ctx) => {
  const { graphic, maskImageWrap } = choozy(node)
  const name = `graphic-${Math.round(Date.now() * Math.random())}`
  const graphicStyle = node.dataset.graphicStyle
  const graphicFill = node.dataset.graphicFill
  const aspect = node.dataset.aspect
  const cellCount = aspect === '2:3' || aspect === '3:2' ? 6 : 4
  let multiIndex = null

  if (graphicStyle === 'multi') {
    multiIndex = gsap.utils.random(0, cellCount - 1, 1)
  }

  const transitionMap = {
    '2:3': ['l', 't', 'l', 'r', 'b', 'r'],
    '3:2': ['l', 'l', 't', 'b', 'r', 'r'],
    '1:1': ['l', 't', 'b', 'r'],
  }

  graphic.innerHTML = `<svg ${
    graphicFill.includes('Image')
      ? `width="0" height="0"`
      : `class="absolute inset-0 w-full h-full" viewBox="${
          aspect === '2:3'
            ? `0 0 4 6`
            : aspect === '3:2'
            ? `0 0 6 4`
            : `0 0 4 4`
        }" fill="none"`
  }>
    ${
      graphicFill.includes('Image')
        ? `<defs><clipPath id="${name}" clipPathUnits="objectBoundingBox" transform="scale(${
            aspect === '3:2' ? '0.1666667' : '0.25'
          } ${aspect === '2:3' ? '0.1666667' : '0.25'})">`
        : ``
    }
    ${new Array(aspect === '1:1' ? 4 : 6)
      .fill()
      .map((_, i) => {
        let translateX = 0
        let translateY = 0
        let transitionDirection = transitionMap[aspect][i]

        if (aspect === '3:2') {
          if (i >= 2) translateX = 2
          if (i >= 4) translateX = 4
          translateY = i % 2 ? 2 : 0
        } else {
          translateX = i % 2 ? 2 : 0
          if (i >= 2) translateY = 2
          if (i >= 4) translateY = 4
        }

        return `${
          i === multiIndex
            ? new Array(4)
                .fill()
                .map((_, j) => {
                  let multiTranslateX = translateX
                  let multiTranslateY = translateY

                  if (aspect === '3:2') {
                    if (j % 2) multiTranslateY += 1
                    if (j >= 2) multiTranslateX += 1
                  } else {
                    if (j % 2) multiTranslateX += 1
                    if (j >= 2) multiTranslateY += 1
                  }

                  return `${getPathByIndex(
                    gsap.utils.random(0, 23, 1),
                    `transform="translate(${multiTranslateX} ${multiTranslateY})" class="js-multiPaths0"`,
                  )}${getPathByIndex(
                    gsap.utils.random(0, 23, 1),
                    `transform="translate(${multiTranslateX} ${multiTranslateY})" class="invisible js-multiPaths1"`,
                  )}${getPathByIndex(
                    gsap.utils.random(0, 23, 1),
                    `transform="translate(${multiTranslateX} ${multiTranslateY})" class="invisible js-multiPaths2"`,
                  )}`
                })
                .join('')
            : getPathByIndex(
                gsap.utils.random(0, 23, 1),
                `transform="translate(${translateX} ${translateY}) scale(2 2)" class="js-paths" data-transition-direction="${transitionDirection}"`,
              )
        }<rect class="invisible js-rects" width="1" height="1" x="0" y="0" transform="translate(${translateX} ${translateY}) scale(2 2)" />`
      })
      .join('')}
      ${graphicFill.includes('Image') ? `</clipPath></defs>` : ``}
  </svg>`

  maskImageWrap.style.clipPath = `url(#${name})`

  function getPathByIndex(n, attrs) {
    const paths = [
      `<path d="M0 0H1V0.433972H0V0Z" fill="black" data-extra="b" ${attrs}/>`,
      `<path d="M8.47281e-07 0H0.433301C0.433248 0.0744349 0.44787 0.14815 0.476331 0.216929C0.504792 0.285708 0.546533 0.348201 0.599166 0.400835C0.6518 0.453468 0.714293 0.495209 0.783072 0.52367C0.851851 0.55213 0.925566 0.566753 1 0.5667V1C0.868631 1.00017 0.738518 0.974422 0.617115 0.924228C0.495712 0.874034 0.385405 0.800381 0.292512 0.707488C0.19962 0.614596 0.125967 0.504289 0.0757728 0.382886C0.0255787 0.261483 -0.000170268 0.13137 8.47281e-07 0V0Z" fill="black" ${attrs}/>`,
      `<path d="M0.3067 0L1 0.6933V1H0.6933L0 0.3067V0H0.3067Z" fill="black" ${attrs}/>`,
      `<path d="M0 1V0H1V0.4333H0.4333V1H0Z" fill="black" ${attrs}/>`,
      `<path d="M0 1V0H0.4333C0.433247 0.0744349 0.44787 0.14815 0.47633 0.216929C0.504791 0.285708 0.546532 0.348201 0.599165 0.400835C0.651799 0.453468 0.714292 0.495209 0.783071 0.52367C0.85185 0.55213 0.925565 0.566753 1 0.5667V1C0.797342 1.00133 0.59929 0.939574 0.4333 0.8233V1H0Z" fill="black" ${attrs}/>`,
      `<path d="M0 1V0H0.3067L1 0.6933V1H0.6933L0.4333 0.74V1H0Z" fill="black" ${attrs}/>`,
      `<path d="M0 1L0 2.23517e-08H0.433972L0.433972 1H0Z" fill="black" data-extra="r" ${attrs}/>`,
      `<path d="M0 0.999999L0 0.566699C0.0744349 0.566752 0.14815 0.55213 0.216929 0.523669C0.285708 0.495208 0.348201 0.453467 0.400835 0.400834C0.453468 0.3482 0.495209 0.285707 0.52367 0.216928C0.55213 0.148149 0.566753 0.0744341 0.5667 -8.16882e-07H1C1.00017 0.131369 0.974422 0.261482 0.924228 0.382885C0.874034 0.504288 0.800381 0.614595 0.707488 0.707488C0.614596 0.80038 0.504289 0.874033 0.382886 0.924227C0.261483 0.974421 0.13137 1.00017 0 0.999999H0Z" fill="black" ${attrs}/>`,
      `<path d="M0 0.6933L0.6933 2.23517e-08L1 2.23517e-08L1 0.3067L0.3067 1H0L0 0.6933Z" fill="black" ${attrs}/>`,
      `<path d="M1 1H0L0 2.23517e-08H0.4333L0.4333 0.5667H1V1Z" fill="black" ${attrs}/>`,
      `<path d="M1 1H0L0 0.5667C0.0744349 0.566753 0.14815 0.55213 0.216929 0.52367C0.285708 0.495209 0.348201 0.453468 0.400835 0.400835C0.453468 0.348201 0.495209 0.285708 0.52367 0.216929C0.55213 0.14815 0.566753 0.0744349 0.5667 2.23517e-08L1 2.23517e-08C1.00133 0.202658 0.939574 0.40071 0.8233 0.5667H1V1Z" fill="black" ${attrs}/>`,
      `<path d="M1 1H0L0 0.6933L0.6933 2.23517e-08L1 2.23517e-08L1 0.3067L0.74 0.5667H1V1Z" fill="black" ${attrs}/>`,
      `<path d="M1 1L2.23517e-08 1V0.566028L1 0.566028V1Z" fill="black" data-extra="t" ${attrs}/>`,
      `<path d="M0.999999 1H0.566699C0.566752 0.925565 0.55213 0.85185 0.523669 0.783071C0.495208 0.714292 0.453467 0.651799 0.400834 0.599165C0.3482 0.546532 0.285707 0.504791 0.216928 0.47633C0.148149 0.44787 0.0744341 0.433247 -8.16882e-07 0.4333L-8.16882e-07 2.23517e-08C0.131369 -0.000171092 0.261482 0.0255779 0.382885 0.075772C0.504288 0.125966 0.614595 0.199619 0.707488 0.292512C0.80038 0.385404 0.874033 0.495711 0.924227 0.617114C0.974421 0.738517 1.00017 0.86863 0.999999 1V1Z" fill="black" ${attrs}/>`,
      `<path d="M0.6933 1L2.23517e-08 0.3067V2.23517e-08H0.3067L1 0.6933V1H0.6933Z" fill="black" ${attrs}/>`,
      `<path d="M1 2.23517e-08L1 1L2.23517e-08 1V0.5667L0.5667 0.5667L0.5667 2.23517e-08H1Z" fill="black" ${attrs}/>`,
      `<path d="M1 2.23517e-08L1 1H0.5667C0.566753 0.925565 0.55213 0.85185 0.52367 0.783071C0.495209 0.714292 0.453468 0.651799 0.400835 0.599165C0.348201 0.546532 0.285708 0.504791 0.216929 0.47633C0.14815 0.44787 0.0744349 0.433247 2.23517e-08 0.4333V2.23517e-08C0.202658 -0.00132718 0.40071 0.0604263 0.5667 0.1767V2.23517e-08H1Z" fill="black" ${attrs}/>`,
      `<path d="M1 2.23517e-08L1 1H0.6933L2.23517e-08 0.3067V2.23517e-08H0.3067L0.5667 0.26V2.23517e-08H1Z" fill="black" ${attrs}/>`,
      `<path d="M1 0L1 1L0.566028 1L0.566028 0L1 0Z" fill="black" data-extra="l" ${attrs}/>`,
      `<path d="M1 8.47281e-07L1 0.433301C0.925565 0.433248 0.85185 0.44787 0.783071 0.476331C0.714292 0.504792 0.651799 0.546533 0.599165 0.599166C0.546532 0.6518 0.504791 0.714293 0.47633 0.783072C0.44787 0.851851 0.433247 0.925566 0.4333 1L2.23517e-08 1C-0.000171092 0.868631 0.0255779 0.738518 0.075772 0.617115C0.125966 0.495712 0.199619 0.385405 0.292512 0.292512C0.385404 0.19962 0.495711 0.125967 0.617114 0.0757728C0.738517 0.0255787 0.86863 -0.000170268 1 8.47281e-07H1Z" fill="black" ${attrs}/>`,
      `<path d="M1 0.3067L0.3067 1L2.23517e-08 1L2.23517e-08 0.6933L0.6933 0L1 0L1 0.3067Z" fill="black" ${attrs}/>`,
      `<path d="M2.23517e-08 0L1 0L1 1L0.5667 1L0.5667 0.4333L2.23517e-08 0.4333L2.23517e-08 0Z" fill="black" ${attrs}/>`,
      `<path d="M2.23517e-08 0L1 0L1 0.4333C0.925565 0.433247 0.85185 0.44787 0.783071 0.47633C0.714292 0.504791 0.651799 0.546532 0.599165 0.599165C0.546532 0.651799 0.504791 0.714292 0.47633 0.783071C0.44787 0.85185 0.433247 0.925565 0.4333 1L2.23517e-08 1C-0.00132718 0.797342 0.0604263 0.59929 0.1767 0.4333H2.23517e-08L2.23517e-08 0Z" fill="black" ${attrs}/>`,
      `<path d="M2.23517e-08 0L1 0L1 0.3067L0.3067 1L2.23517e-08 1L2.23517e-08 0.6933L0.26 0.4333L2.23517e-08 0.4333L2.23517e-08 0Z" fill="black" ${attrs}/>`,
    ]

    return n >= 0 && n <= paths.length - 1 ? paths[n] : ''
  }

  return () => {}
})
