import choozy from 'choozy'
import delegate from '../lib/delegate'
import { on, remove, add } from 'martha'
import { component } from 'picoapp'
import gsap from 'gsap'

export default component((node, ctx) => {
  const {
    title,
    playPauseButton,
    playIcon,
    pauseIcon,
    currentTime,
    progressBackground,
    progressBar,
    duration,
    media,
  } = choozy(node)

  let isHidden = true

  delegate(document.body, '.js-playPodcastButton', 'click', el => {
    media.pause()

    const li = el.closest('li')
    const id = li?.getAttribute('id')

    if (id) {
      history.pushState({}, '', `#${id}`)
    }

    title.textContent = el.dataset.title
    media.src = el.dataset.audioUrl

    if (isHidden) {
      isHidden = false
      gsap.set(node, { yPercent: 100, autoAlpha: 1 })
      gsap.to(node, {
        yPercent: 0,
        duration: 1,
        ease: 'circ.inOut',
        onComplete: play,
      })
    } else {
      play()
    }
  })

  function play() {
    media.play()
  }

  on(media, 'loadedmetadata', () => {
    duration.textContent = formatSeconds(media.duration)
  })

  on(media, 'play', () => {
    remove(pauseIcon, 'hidden')
    add(playIcon, 'hidden')
  })

  on(media, 'pause', () => {
    add(pauseIcon, 'hidden')
    remove(playIcon, 'hidden')
  })

  on(media, 'timeupdate', () => {
    currentTime.textContent = formatSeconds(media.currentTime)
    gsap.set(progressBar, {
      scaleX: media.currentTime / media.duration,
    })
  })

  on(progressBackground, 'click', ev => {
    media.currentTime =
      (ev.offsetX / progressBackground.offsetWidth) * media.duration
  })

  on(playPauseButton, 'click', ev => {
    if (media.paused) {
      media.play()
    } else {
      media.pause()
    }
  })
})

function formatSeconds(s) {
  return new Date(s * 1000).toISOString().substring(14, 19)
}
