export default function isUrlExternal(url, host) {
  if (isAbsoluteUrl(url)) {
    const providedHost = getHostFromUrl(url)
    return providedHost !== host
  } else {
    return false
  }
}

function getHostFromUrl(url) {
  return new URL(url).hostname.replace('www.', '')
}

function isAbsoluteUrl(url) {
  const formattedUrl = url.toLowerCase()
  return formattedUrl.startsWith('http')
}
