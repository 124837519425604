import choozy from 'choozy'
import { add, attr, each, on, remove, wrap } from 'martha'
import { component } from 'picoapp'

export default component((node, ctx) => {
  const { tabs, tabpanels } = choozy(node)

  each(tabs, tab => {
    attr(tab, 'tabindex', -1)
    attr(tab, 'aria-selected', 'false')
  })

  const offKeydown = on(tabs, 'keydown', ev => {
    let flag = false

    switch (ev.key) {
      case 'ArrowLeft':
        setSelectedTab(
          tabs[wrap(tabs.indexOf(ev.currentTarget) - 1, tabs.length)],
        )
        flag = true
        break

      case 'ArrowRight':
        setSelectedTab(
          tabs[wrap(tabs.indexOf(ev.currentTarget) + 1, tabs.length)],
        )
        flag = true
        break

      case 'Home':
        setSelectedTab(tabs[0])
        flag = true
        break

      case 'End':
        setSelectedTab(tabs[tabs.length - 1])
        flag = true
        break

      default:
        break
    }

    if (flag) {
      ev.stopPropagation()
      ev.preventDefault()
    }
  })

  const offClick = on(tabs, 'click', ev => setSelectedTab(ev.currentTarget))

  ctx.on('tabs:select', (_, el) => {
    setSelectedTab(el, false)
  })

  setSelectedTab(tabs[0], false)

  function setSelectedTab(currentTab, setFocus = true) {
    each(tabs, (tab, i) => {
      if (currentTab === tab) {
        attr(tab, 'aria-selected', 'true')
        attr(tab, 'tabindex', null)
        add(tab, 'bg-black text-white')
        remove(tabpanels[i], 'hidden')
        if (setFocus) tab.focus()
      } else {
        attr(tab, 'aria-selected', 'false')
        attr(tab, 'tabindex', -1)
        remove(tab, 'bg-black text-white')
        add(tabpanels[i], 'hidden')
      }
    })
  }

  return () => {
    offKeydown()
    offClick()
  }
})
