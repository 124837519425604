import { component } from 'picoapp'
import choozy from 'choozy'
import gsap from 'gsap'
import { qs, on, rect, each, add, remove } from 'martha'
import Plyr from 'plyr'

export default component((node, ctx) => {
  let { content, contentItems, btns, mediaItems } = choozy(node)
  contentItems = [].concat(contentItems)
  mediaItems = [].concat(mediaItems)

  each(mediaItems, item => {
    const videoPlayerEl = qs('[data-plyr-embed-id]', item)
    if (videoPlayerEl) {
      item.videoPlayer = new Plyr(videoPlayerEl, {
        tooltips: { controls: false, seek: false },
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'captions',
          'fullscreen',
        ],
      })
    }
  })

  const tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 0.3,
      ease: 'circ.inOut',
    },
  })

  let prevIndex = 0
  let currentIndex = 0
  let isAnimating = 0

  ctx.on('resize', () => {
    gsap.set(content, {
      height: Math.max(...contentItems.map(el => rect(el).height)),
    })
  })

  if (btns?.length) {
    on(btns, 'click', ({ currentTarget: t }) => {
      const item = mediaItems[currentIndex]
      item?.videoPlayer?.pause?.()
      animateTo(+t.dataset.index)
    })
  }

  function animateTo(index) {
    if (isAnimating) return
    isAnimating = true

    prevIndex = currentIndex
    currentIndex = index

    // Exiting slide DOM elements
    const prevBtn = btns[prevIndex]
    const prevContent = contentItems[prevIndex]
    const prevMedia = mediaItems[prevIndex]

    // Entering slide DOM elements
    const currentBtn = btns[currentIndex]
    const currentContent = contentItems[currentIndex]
    const currentMedia = mediaItems[currentIndex]

    each(contentItems, (el, i) => {
      gsap.set(el, { pointerEvents: i === currentIndex ? 'auto' : 'none' })
    })

    remove(currentBtn, 'opacity-50')
    add(currentBtn, 'pointer-events-none border-b-1')
    remove(prevBtn, 'pointer-events-none border-b-1')
    add(prevBtn, 'opacity-50')

    tl.clear()
      .to(
        prevMedia,
        {
          autoAlpha: 0,
        },
        0,
      )
      .to(
        currentMedia,
        {
          autoAlpha: 1,
        },
        0,
      )
      .to(
        prevContent,
        {
          x: 10,
          autoAlpha: 0,
          ease: 'circ',
        },
        0,
      )
      .fromTo(
        currentContent,
        { autoAlpha: 0, x: -10 },
        {
          x: 0,
          autoAlpha: 1,
        },
        0,
      )
      .add(() => {
        isAnimating = false
      })
      .restart()
  }
})
