import choozy from 'choozy'
import { on, add, remove } from 'martha'
import { component } from 'picoapp'
import Cookies from 'js-cookie'

export default component((node, ctx) => {
  const { dismissButton } = choozy(node)

  if (Cookies.get('hide-cookie-notice') !== 'true') {
    show()
  }

  on(dismissButton, 'click', hide)

  function show() {
    remove(node, 'invisible')
  }

  function hide() {
    Cookies.set('hide-cookie-notice', 'true', { expires: 365 })
    add(node, 'invisible')
  }
})
