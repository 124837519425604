import choozy from 'choozy'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import { add, remove, each } from 'martha'
import { component } from 'picoapp'
import hover from '../lib/hover'

export default component((node, ctx) => {
  const { idleText, hoverText } = choozy(node)
  const states = [
    'font-display-100',
    'font-display-75',
    'font-display-50',
    'font-display-25',
    'font-display-0',
    'opacity-0',
  ]

  idleText.split = new SplitText(idleText, {
    charsClass: 'font-display-100',
    type: 'words,chars',
  })

  each(idleText.split.chars, char => {
    char.state = 0
  })

  hoverText.split = new SplitText(hoverText, {
    charsClass: 'opacity-0',
    type: 'words,chars',
  })

  remove(hoverText, 'invisible')
  each(hoverText.split.chars, char => {
    char.state = 5
  })

  const tl = gsap.timeline({ paused: true })

  hover(node, {
    enter: () => {
      tl.clear()
        .to(idleText.split.chars, {
          state: 5,
          ease: 'steps(5)',
          duration: 0.2,
          stagger: {
            each: -0.2 / 5,
            ease: 'none',
          },
          onUpdate() {
            each(idleText.split.chars, char => {
              char.className = ''
              add(char, states[char.state])
            })
          },
        })
        .to(hoverText.split.chars, {
          state: 0,
          ease: 'steps(5)',
          duration: 0.2,
          stagger: {
            each: 0.2 / 5,
            ease: 'none',
          },
          onUpdate() {
            each(hoverText.split.chars, char => {
              char.className = ''
              add(char, states[char.state])
            })
          },
        })
        .restart()
    },
    leave: () => {
      tl.clear()
        .to(hoverText.split.chars, {
          state: 5,
          ease: 'steps(5)',
          duration: 0.2,
          stagger: {
            each: -0.2 / 5,
            ease: 'none',
          },
          onUpdate() {
            each(hoverText.split.chars, char => {
              char.className = ''
              add(char, states[char.state])
            })
          },
        })
        .to(idleText.split.chars, {
          state: 0,
          ease: 'steps(5)',
          duration: 0.2,
          stagger: {
            each: 0.2 / 5,
            ease: 'none',
          },
          onUpdate() {
            each(idleText.split.chars, char => {
              char.className = ''
              add(char, states[char.state])
            })
          },
        })
        .restart()
    },
  })
})
