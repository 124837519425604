import { picoapp } from 'picoapp'
import { size, has } from 'martha'

import accordion from './components/accordion'
import accordionWithMedia from './components/accordionWithMedia'
import featuredContentCarousel from './components/featuredContentCarousel'
import fitText from './components/fitText'
import form from './components/form'
import header from './components/header'
import idDisplayHover from './components/idDisplayHover'
import idModuleGraphic from './components/idModuleGraphic'
import img from './components/img'
import interactiveTables from './components/interactiveTables'
import listing from './components/listing'
import logo from './components/logo'
import marquee from './components/marquee'
import mediaCarousel from './components/mediaCarousel'
import menu from './components/menu'
import navBar from './components/navBar'
import podcastPlayer from './components/podcastPlayer'
import slider from './components/slider'
import tabs from './components/tabs'
import videoLoop from './components/videoLoop'
import quickSearch from './components/quickSearch'
import searchPage from './components/searchPage'
import cookieNotice from './components/cookieNotice'
import reduceMotionToggle from './components/reduceMotionToggle'

const components = {
  accordion,
  accordionWithMedia,
  featuredContentCarousel,
  fitText,
  form,
  header,
  idDisplayHover,
  idModuleGraphic,
  img,
  interactiveTables,
  listing,
  logo,
  marquee,
  mediaCarousel,
  menu,
  navBar,
  podcastPlayer,
  slider,
  tabs,
  videoLoop,
  quickSearch,
  searchPage,
  cookieNotice,
  reduceMotionToggle,
}

const store = {
  ...size(),
  isMobile: has(document.body, 'is-mobile'),
  isMenuOpen: false,
  isQuickSearchActive: false,
  prefersReducedMotion: false,
}

export default picoapp(components, store)
