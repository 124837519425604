import { component } from 'picoapp'
import gsap from 'gsap'
import choozy from 'choozy'
import { createFocusTrap } from 'focus-trap'
import { add, remove, noop, on } from 'martha'

export default component((node, ctx) => {
  const { links } = choozy(node)

  const trap = createFocusTrap(node, {
    escapeDeactivates: false,
  })

  const tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 1,
      ease: 'circ.inOut',
    },
  })

  let offKeydown = noop

  ctx.on('menu:toggle', ({ isMenuOpen, maxOffset }) => {
    tl.clear()

    if (isMenuOpen) {
      add(document.body, 'overflow-hidden')

      offKeydown = on(document, 'keydown', ({ key }) => {
        if (key === 'Escape') {
          offKeydown()
          offKeydown = noop
          ctx.emit('menu:toggle', { isMenuOpen: false })
        }
      })

      tl.set(node, { y: maxOffset, pointerEvents: 'auto' })
        .set(links, { autoAlpha: 0 })
        .add(() => trap.activate())
        .to(
          node,
          {
            y: 0,
            '--y': 0,
          },
          0,
        )
        .to(links, { autoAlpha: 1 }, 0)
    } else {
      offKeydown()
      remove(document.body, 'overflow-hidden')
      trap.deactivate()

      tl.to(
        node,
        {
          y: maxOffset,
          '--y': 100,
          pointerEvents: 'none',
        },
        0,
      )
        .to(links, { autoAlpha: 0 }, 0)
        .set(node, { y: 0 })
    }

    tl.restart()
  })

  return () => {
    offKeydown()
  }
})
