import config from './config.js'

export const TYPES = {
  page: 'Page',
  course: 'Course',
  eventbrite_events: 'Event',
  person: 'Person',
  student: 'Student',
  project: 'Project',
  story: 'Story',
}

export const TYPES_PLURAL = {
  page: 'Pages',
  course: 'Courses',
  eventbrite_events: 'Events',
  person: 'People',
  student: 'Students',
  project: 'Projects',
  story: 'News & Stories',
}

export const INDEX_NAME = config.ALGOLIA_INDEX
