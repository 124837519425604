import { component } from 'picoapp'
import choozy from 'choozy'
import { add, on, remove, qs, attr, qsa, wrap, index } from 'martha'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { searchClient } from '../lib/algolia'
import { TYPES, INDEX_NAME } from '../lib/constants'

export default component((node, ctx) => {
  const { toggle, container, panelContainer } = choozy(node)
  const removeDuplicates = uniqBy(({ item }) => item.parent_title)
  const { setQuery } = autocomplete({
    container,
    panelContainer,
    placeholder: 'Search id.iit.edu',
    detachedMediaQuery: 'none',
    reshape(props) {
      return [removeDuplicates(props.sourcesBySourceId.main)]
    },
    getSources({ query }) {
      return [
        {
          sourceId: 'main',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: INDEX_NAME,
                  query,
                  params: {
                    hitsPerPage: 5,
                  },
                },
              ],
            })
          },
          templates: {
            item({ item, components, html }) {
              return html`<a
                class="block pt-15 px-20 pb-18 bg-black hover:bg-gray-90 focus-visible:bg-gray-90 border-b-1 border-b-gray-90"
                href="${item.link}"
              >
                <div class="text-20 leading-132 tracking-2 mb-5">
                  ${components.Highlight({
                    hit: item,
                    attribute: 'parent_title',
                  })}
                </div>
                <div
                  class="text-12 font-normal leading-100 tracking-6 uppercase text-gray-50"
                >
                  ${TYPES[item.type]}
                </div>
              </a>`
            },
          },
        },
      ]
    },
    render({ state, sections, render, html }, root) {
      const href = `/search${state.query.length ? `?query=${state.query}` : ``}`
      render(
        html`<div class="aa-PanelWrap">
          ${sections}
          <div class="p-20">
            <a
              class="block border-1 border-gray-90 rounded-full w-full text-center text-26 leading-100 tracking-1 text-white py-6 hover:bg-gray-90 focus-visible:bg-gray-90 js-seeAllButton"
              href="${href}"
              >See All Results</a
            >
          </div>
        </div>`,
        root,
      )
    },
    renderNoResults({ state, render, html }, root) {
      render(
        html`<div class="text-20 leading-132 tracking-2 px-20 pt-20 pb-30">
          No results for “${state.query}”
        </div>`,
        root,
      )
    },
  })

  const submitButton = qs('.aa-SubmitButton')
  submitButton.innerHTML = `<svg class="w-13" viewBox="0 0 13 18" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.12191 11.4858C11.7158 9.98827 12.6045 6.67149 11.1069 4.07761C9.60936 1.48374 6.29258 0.595008 3.69871 2.09258C1.10483 3.59016 0.216102 6.90694 1.71368 9.50081C3.21125 12.0947 6.52803 12.9834 9.12191 11.4858ZM9.61492 12.3398C12.6804 10.5699 13.7307 6.65008 11.9609 3.58459C10.191 0.519104 6.27118 -0.53121 3.20569 1.23865C0.140198 3.00851 -0.910117 6.92834 0.859745 9.99383C2.62961 13.0593 6.54943 14.1096 9.61492 12.3398Z" class="fill-current"></path>
    <path d="M8.69494 11.7324L9.54887 11.2393L13 17.2169L12.1461 17.7099L8.69494 11.7324Z" class="fill-current"></path>
  </svg>`

  on(toggle, 'click', () => {
    ctx.emit('quickSearch:toggle', { isQuickSearchActive: true })
  })

  on(document, 'pointerdown', ev => {
    if (ev.target.closest('#quick-search, .aa-Panel')) return
    ctx.emit('quickSearch:toggle', { isQuickSearchActive: false })
  })

  on(document, 'keydown', ev => {
    if (!ctx.getState().isQuickSearchActive) return

    if (ev.key === 'Escape') {
      setQuery('')
    }

    if (ev.key === 'Enter') {
      if (document.activeElement.classList.contains('aa-Input')) {
        const href = attr(qs('.js-seeAllButton'), 'href')
        if (href) {
          window.location.href = href
        }
      }
    }

    if (ev.key === 'ArrowDown' || ev.key === 'ArrowUp') {
      ev.preventDefault()

      const delta = ev.key === 'ArrowDown' ? 1 : -1
      const tabbables = qsa('a, input[type="search"]', node)
      const nextIndex = wrap(
        tabbables.findIndex(el => el === document.activeElement) + delta,
        tabbables.length,
      )

      const nextElement = tabbables[nextIndex]
      nextElement.focus()
    }
  })

  ctx.on('quickSearch:toggle', ({ isQuickSearchActive }) => {
    if (isQuickSearchActive) {
      remove(container, 'invisible')
      const input = qs('.aa-Input', container)
      input.focus()
    } else {
      add(container, 'invisible')
      setQuery('')
    }
  })
})

function uniqBy(predicate) {
  return function runUniqBy(...rawSources) {
    const sources = rawSources.flat().filter(Boolean)
    const seen = new Set()

    return sources.map(source => {
      const items = source.getItems().filter(item => {
        const appliedItem = predicate({ source, item })
        const hasSeen = seen.has(appliedItem)

        seen.add(appliedItem)

        return !hasSeen
      })

      return {
        ...source,
        getItems() {
          return items
        },
      }
    })
  }
}
