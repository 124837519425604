import { component } from 'picoapp'
import choozy from 'choozy'
import { each, on, remove, qs, add } from 'martha'
import app from '../app'
import * as cache from '../lib/cache'

export default component((node, ctx) => {
  const { form, inputs, listing } = choozy(node)
  const parser = new DOMParser()

  const searchParams = new URLSearchParams(window.location.search)
  const params = {}
  searchParams.forEach((v, k) => {
    const values = v.split(',').sort()
    params[k] = values
    each(inputs, input => {
      if (input.name === k && values.includes(input.value)) {
        input.checked = true
      }
    })
  })

  let skeleton = null
  let popping = false

  on(inputs, 'change', handleChange)

  on(window, 'popstate', () => {
    popping = true

    new URLSearchParams(window.location.search).forEach((value, name) => {
      const tags = value.split(',')

      inputs.forEach(input => {
        input.checked = false
      })

      tags.forEach(tag => {
        const input = qs(`input[name=${name}][value=${tag}]`)
        if (input) {
          input.checked = true
        }
      })
    })

    popping = false

    handleChange()
  })

  async function handleChange(pushState) {
    if (popping) return

    const formData = getFormData(form)
    skeleton = qs('.js-skeleton', listing)

    remove(skeleton, 'hidden')
    add(form, 'pointer-events-none opacity-70')

    let basePath = window.location.pathname.split('page/')[0]

    if (basePath.slice(-1) === '/') {
      basePath = basePath.slice(0, -1)
    }

    if (pushState) {
      window.history.pushState(
        null,
        '',
        new URL(
          `${basePath}${
            Object.keys(formData).length ? '/?' : ''
          }${Object.entries(formData)
            .map(([key, values]) => `${key}=${values.join(',')}`)
            .join('&')}`,
          window.location.origin,
        ),
      )
    }

    const newListing = await cache.get(async () => {
      const html = await fetch(window.location.href).then(res => res.text())
      const doc = parser.parseFromString(html, 'text/html')
      return qs('.js-listing', doc.body)
    }, window.location.href)

    add(skeleton, 'hidden')
    remove(form, 'pointer-events-none opacity-70')

    listing.innerHTML = newListing.innerHTML

    app.mount()
    ctx.emit('resize')
  }

  return () => {
    offChange()
  }
})

function getFormData(form) {
  const object = {}
  new FormData(form).forEach((value, key) => {
    object[key] = (Reflect.has(object, key) ? object[key] : []).concat(value)
  })
  return object
}

function updateFormFromUrl(url) {}
