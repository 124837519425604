import choozy from 'choozy'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import { each, on, wrap } from 'martha'
import { component } from 'picoapp'
import fitty from 'fitty'

export default component((node, ctx) => {
  const { fit, logo } = choozy(node)
  const fitties = fitty(fit)

  let offFit = on(fit, 'fit', () => {
    const fontSize = getComputedStyle(fit).getPropertyValue('font-size')
    gsap.set(logo, { fontSize })
  })

  logo.split = new SplitText(logo, {
    charsClass: 'font-display-100',
    type: 'words,chars',
  })

  const states = [100, 75, 50, 25, 0]

  each(logo.split.chars, char => {
    char.state = 0
  })

  const tl = gsap.timeline({ paused: true })

  const update = chars => {
    each(chars, char => {
      char.className = `font-display-${states[Math.round(char.state)]}`
    })
  }

  on(logo.split.chars, 'mouseenter', ({ currentTarget: char }) => {
    if (!ctx.getState().prefersReducedMotion) {
      char.state = wrap(char.state + 1, 5)
      char.className = `font-display-${states[char.state]}`
    }

    tl.clear()
    tl.to(
      logo.split.chars,
      {
        state: 0,
        ease: 'steps(5)',
        duration: 0.2,
        stagger: {
          each: 0.2 / 5,
          from: 'random',
          ease: 'none',
        },
        onUpdate: () => update(logo.split.chars),
      },
      0.2,
    )
    tl.restart()
  })

  return () => {
    offFit()
    fitties.unsubscribe()
  }
})
