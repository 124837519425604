import { component } from 'picoapp'
import choozy from 'choozy'
import { each, on, remove, add, wrap, rect, qsa } from 'martha'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'

export default component((node, ctx) => {
  const duration = +node.dataset.duration
  const {
    headlineWrap,
    headlines,
    btns,
    circles,
    imgs,
    relatedContent,
    linkGroups,
  } = choozy(node)

  const tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 1,
      ease: 'circ.inOut',
    },
  })

  let prevIndex = 0
  let currentIndex = 0
  let isAnimating = false
  let isAutoplayEnabled = ctx.getState().prefersReducedMotion ? false : true

  each(imgs, img => {
    img.refs = choozy(img)

    each(img.refs.rects, rect => {
      gsap.set(rect, { autoAlpha: 0 })
    })
  })

  ctx.on('resize', () => {
    each(headlines, (el, i) => {
      el?.split?.revert?.()
      el.split = new SplitText(el, { type: 'words,chars' })
      gsap.set(el.split.chars, { autoAlpha: i === currentIndex ? 1 : 0 })
    })

    gsap.set(headlineWrap, {
      height: Math.max(...Array.from(headlines).map(el => rect(el).height)),
    })

    gsap.set(relatedContent, {
      height: Math.max(
        ...Array.from(relatedContent.children).map(el => rect(el).height),
      ),
    })
  })

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      animateTo(0, true)
    })
  })

  let offClick = on(btns, 'click', ({ currentTarget: t }) => {
    isAutoplayEnabled = false
    animateTo(+t.dataset.index)
  })

  function animateTo(index, init = false) {
    if (isAnimating) return
    isAnimating = true

    prevIndex = currentIndex
    currentIndex = index

    // Exiting slide DOM elements
    const prevBtn = btns[prevIndex]
    const prevHeadline = headlines[prevIndex]
    const prevChars = prevHeadline.split.chars
    const prevCircle = circles[prevIndex]
    const prevImg = imgs[prevIndex]
    const prevLinkGroup = linkGroups[prevIndex]
    const prevLinks = qsa('.js-links', prevLinkGroup)

    // Entering slide DOM elements
    const currentBtn = btns[currentIndex]
    const currentHeadline = headlines[currentIndex]
    const currentChars = currentHeadline.split.chars
    const currentCircle = circles[currentIndex]
    const currentImg = imgs[currentIndex]
    const currentLinkGroup = linkGroups[currentIndex]
    const currentLinks = qsa('.js-links', currentLinkGroup)

    each(linkGroups, (el, i) => {
      gsap.set(el, { pointerEvents: i === currentIndex ? 'auto' : 'none' })
    })

    remove(currentBtn, 'text-gray-50')
    add(currentBtn, 'text-black pointer-events-none')

    if (!init) {
      add(prevBtn, 'text-gray-50')
      remove(prevBtn, 'text-black pointer-events-none')
    }

    tl.clear()

    if (init || prevIndex !== currentIndex) {
      tl.set(prevImg.refs.rects, { autoAlpha: 1 })
      tl.set(currentImg.refs.rects, {
        autoAlpha: () => (ctx.getState().prefersReducedMotion ? 1 : 0),
      })
    }

    each(imgs, (img, i) => {
      if (i === currentIndex) {
        gsap.set(img, { autoAlpha: 0, zIndex: 3 })
      } else if (i === prevIndex) {
        gsap.set(img, { autoAlpha: 1, zIndex: 2 })
      } else {
        gsap.set(img, { autoAlpha: 0, zIndex: 1 })
      }
    })

    if (!ctx.getState().prefersReducedMotion) {
      tl.set(currentImg, { autoAlpha: 1 })

      each(currentImg.refs.paths, path => {
        const dir = path.dataset.transitionDirection
        const extra = path.dataset.extra

        let offset = 200

        if (dir === extra) {
          offset = 466
        }

        tl.set(path, {
          yPercent: dir === 't' ? -offset : dir === 'b' ? offset : 0,
          xPercent: dir === 'l' ? -offset : dir === 'r' ? offset : 0,
        })
      })

      tl.set(
        [
          currentImg.refs.multiPaths0,
          currentImg.refs.multiPaths1,
          currentImg.refs.multiPaths2,
        ],
        { autoAlpha: 0 },
      )
    }

    tl.set(currentChars, { autoAlpha: 0 }).set(currentHeadline, {
      autoAlpha: 1,
    })

    if (ctx.getState().prefersReducedMotion) {
      tl.to(
        prevChars,
        {
          autoAlpha: 0,
          ease: 'power3',
          duration: 0.5,
        },
        0,
      )
        .to(
          currentChars,
          {
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power3',
          },
          0.25,
        )
        .to(
          prevCircle,
          {
            strokeDashoffset: 82,
            duration: 0,
          },
          0,
        )
        .to(
          currentImg,
          {
            autoAlpha: 1,
            duration: 0.75,
            ease: 'power3',
          },
          0,
        )
        .to(
          prevLinks,
          {
            autoAlpha: 0,
            ease: 'power3',
            x: 0,
            duration: 0.5,
          },
          0,
        )
        .fromTo(
          currentLinks,
          {
            autoAlpha: 0,
            x: 0,
          },
          {
            x: 0,
            autoAlpha: 1,
            ease: 'power3',
            duration: 0.5,
            delay: 0.25,
          },
          0,
        )
    } else {
      tl.to(
        prevChars,
        {
          autoAlpha: 0,
          duration: 0,
          stagger: {
            from: 'random',
            amount: 0.5,
          },
        },
        0,
      )
        .to(
          currentChars,
          {
            autoAlpha: 1,
            duration: 0,
            stagger: {
              from: 'random',
              amount: 0.5,
            },
          },
          0.5,
        )
        .to(
          prevCircle,
          {
            strokeDashoffset: 82,
            duration: 1,
          },
          0,
        )
        .to(
          currentImg.refs.paths,
          {
            xPercent: 0,
            yPercent: 0,
            ease: 'circ.inOut',
            duration: 1.25,
            stagger: 0.25,
          },
          0,
        )
        .to(
          currentImg.refs.multiPaths0,
          {
            autoAlpha: 1,
            duration: 0,
            ease: 'none',
            stagger: {
              each: 0.1,
              onUpdate() {
                gsap.set(this._targets, { autoAlpha: 0, delay: 0.1 })
              },
            },
          },
          '>-=0.25',
        )
        .to(
          currentImg.refs.multiPaths1,
          {
            autoAlpha: 1,
            duration: 0,
            ease: 'none',
            stagger: {
              each: 0.1,
              onUpdate() {
                gsap.set(this._targets, { autoAlpha: 0, delay: 0.1 })
              },
            },
          },
          '<+=0.1',
        )
        .to(
          currentImg.refs.multiPaths2,
          {
            autoAlpha: 1,
            duration: 0,
            ease: 'none',
            stagger: {
              each: 0.1,
            },
          },
          '<+=0.1',
        )
        .to(
          currentImg.refs.rects,
          {
            autoAlpha: 1,
            duration: 0,
            ease: 'none',
            stagger: {
              each: 0.1,
              from: 'random',
            },
          },
          '>',
        )
        .to(
          prevLinks,
          {
            autoAlpha: 0,
            ease: 'circ',
            x: 10,
            duration: 1,
            stagger: 0.05,
          },
          0,
        )
        .fromTo(
          currentLinks,
          {
            autoAlpha: 0,
            x: -10,
          },
          {
            x: 0,
            autoAlpha: 1,
            ease: 'circ.inOut',
            duration: 1,
            stagger: 0.05,
          },
          0,
        )
    }

    tl.fromTo(
      currentCircle,
      {
        strokeDashoffset: 82,
      },
      {
        strokeDashoffset: 0,
        duration: () => (ctx.getState().prefersReducedMotion ? 0.001 : 1),
        onComplete() {
          isAnimating = false
        },
      },
      0,
    )

    if (isAutoplayEnabled) {
      tl.fromTo(
        currentCircle,
        {
          strokeDashoffset: 164,
        },
        {
          duration,
          strokeDashoffset: 82,
          ease: 'none',
          onComplete() {
            animateTo(wrap(currentIndex + 1, headlines.length))
          },
        },
        '>',
      )
    }

    tl.restart()
  }

  return () => {
    offClick()
  }
})
