import { on, each, rect, qsa } from 'martha'
import { component } from 'picoapp'
import choozy from 'choozy'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'

export default component((node, ctx) => {
  const {
    menuToggle,
    monogram,
    horizontal,
    topBun,
    patty,
    bottomBun,
    menuText,
    closeText,
  } = choozy(node)

  const state = {
    scale: null,
    y: null,
    factor: 0.25,
  }

  const tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 1,
      ease: 'circ.inOut',
    },
  })

  menuText.split = new SplitText(menuText, {
    type: 'words,chars',
  })
  closeText.split = new SplitText(closeText, {
    type: 'words,chars',
  })

  monogram.split = new SplitText(monogram, {
    charsClass: 'font-display-100',
    type: 'words,chars',
  })

  each(monogram.split.chars, char => {
    char.state = 5
  })

  horizontal.split = new SplitText(horizontal, {
    charsClass: 'font-display-100',
    type: 'words,chars',
  })

  each(horizontal.split.chars, char => {
    char.state = 0
    gsap.set(char, { alpha: 0 })
  })

  const update = chars => {
    each(chars, char => {
      const cx = Math.round(char.state) * 25 - 25
      if (cx < 0) {
        gsap.set(char, { alpha: 0 })
      } else {
        gsap.set(char, { alpha: 1 })
        char.className = `font-display-${cx}`
      }
    })
  }

  on(menuToggle, 'click', () => {
    const headers = qsa('[data-header]')
    gsap.set(headers, { clearProps: 'all' })

    ctx.emit('menu:toggle', {
      isMenuOpen: !ctx.getState().isMenuOpen,
      maxOffset: Math.max(...headers.map(header => rect(header).top)),
    })
  })

  ctx.on('resize', () => {
    monogram.rect = rect(monogram)
    horizontal.rect = rect(horizontal)

    state.scale = horizontal.rect.height / monogram.rect.height
    state.y = horizontal.rect.y - monogram.rect.y
  })

  ctx.on('menu:toggle', ({ isMenuOpen, maxOffset }) => {
    const isHeader = !node.closest('[data-menu]')

    if (isMenuOpen) {
      tl.clear()

      if (isHeader) {
        tl.set([node, '[data-logo]'], { y: 0 })
      }

      tl.set(closeText.split.chars, { autoAlpha: 0 })
        .set(closeText, { autoAlpha: 1 })
        .set(horizontal, { scale: 1 / state.scale, y: -state.y, autoAlpha: 1 })

      if (isHeader) {
        tl.to([node, '[data-logo]'], { y: -maxOffset }, 0)
      }

      tl.to(monogram, { scale: state.scale, y: state.y }, 0)
        .to(horizontal, { scale: 1, y: 0 }, 0)
        .to(
          monogram.split.chars,
          {
            state: 0,
            ease: 'steps(6)',
            duration: state.factor,
            stagger: {
              each: -state.factor / 6,
              ease: 'none',
            },
            onUpdate: () => update(monogram.split.chars),
          },
          0,
        )
        .to(
          horizontal.split.chars,
          {
            state: 5,
            ease: 'steps(6)',
            duration: state.factor,
            stagger: {
              each: state.factor / 6,
              ease: 'none',
            },
            onUpdate: () => update(horizontal.split.chars),
          },
          '>',
        )
        .to(patty, { scaleX: 0 }, 0)
        .to([topBun, bottomBun], { rotation: gsap.utils.wrap([-15, 15]) }, 0)
        .to(
          menuText.split.chars,
          {
            autoAlpha: 0,
            duration: 0,
            stagger: {
              from: 'random',
              each: 0.05,
            },
          },
          0.4,
        )
        .to(
          closeText.split.chars,
          {
            autoAlpha: 1,
            duration: 0,
            stagger: {
              from: 'random',
              each: 0.05,
            },
          },
          0.6,
        )
        .set(monogram, { scale: 1, y: 0 })
        .restart()
    } else {
      tl.clear()

      if (isHeader) {
        tl.set([node, '[data-logo]'], { y: -maxOffset })
      }

      tl.set(monogram, { scale: state.scale, y: state.y })

      if (isHeader) {
        tl.to(
          [node, '[data-logo]'],
          {
            y: 0,
          },
          0,
        )
      }

      tl.to(monogram, { scale: 1, y: 0 }, 0)
        .to(horizontal, { scale: 1 / state.scale, y: -state.y }, 0)
        .to(
          horizontal.split.chars,
          {
            state: 0,
            ease: 'steps(6)',
            duration: state.factor,
            stagger: {
              each: -state.factor / 6,
              ease: 'none',
            },
            onUpdate: () => update(horizontal.split.chars),
          },
          0,
        )
        .to(
          monogram.split.chars,
          {
            state: 5,
            ease: 'steps(6)',
            duration: state.factor,
            stagger: {
              each: state.factor / 6,
              ease: 'none',
            },
            onUpdate: () => update(monogram.split.chars),
          },
          '>',
        )
        .to(patty, { scaleX: 1 }, 0)
        .to([topBun, bottomBun], { rotation: 0 }, 0)
        .to(
          closeText.split.chars,
          {
            autoAlpha: 0,
            duration: 0,
            stagger: {
              from: 'random',
              each: 0.05,
            },
          },
          0.4,
        )
        .to(
          menuText.split.chars,
          {
            autoAlpha: 1,
            duration: 0,
            stagger: {
              from: 'random',
              each: 0.05,
            },
          },
          0.6,
        )
        .set(horizontal, { scale: 1, y: 0, autoAlpha: 0 })
        .restart()
    }
  })
})
